<template>
  <div class="channel-join-wrapper">
    <div class="banner-container">
      <div class="info">
        <h1 class="title">魔筷代理商全国火热招募中</h1>
        <div class="desc">携手魔筷共同开拓千亿直播电商市场</div>
        <a href="#apply" class="btn">我要成为合作伙伴</a>
      </div>
    </div>
    <div class="search-container">
      <div class="search-box">
        <input
          v-model="searchKey"
          type="text"
          class="search-input"
          placeholder="查询魔筷授权的代理商，请填写代理商全称"
        />
        <div class="btn" @click="search">搜索</div>
      </div>
      <h2 class="title">成为魔筷代理商，收益高，资源足，市场大</h2>
      <div class="content-box">
        <div class="info">
          <div v-for="(item, index) in searchBoxData" :key="index" class="item">
            <img :src="item.icon" alt="" class="icon" />
            <div class="right">
              <h3 class="title">{{ item.title }}</h3>
              <div class="desc">{{ item.desc }}</div>
            </div>
          </div>
          <a href="#apply" class="btn">我要成为合作伙伴</a>
        </div>
        <div class="image">
          <img
            src="https://mktv-in-cdn.mockuai.com/16269435921574198.png"
            alt=""
            class="left"
          />
          <img
            src="https://mktv-in-cdn.mockuai.com/16269435921615242.png"
            alt=""
            class="right"
          />
        </div>
      </div>
    </div>
    <div class="service-container">
      <h2 class="title">免费成为魔筷代理商，扶持助您成功</h2>
      <div class="service-list">
        <div
          v-for="(item, index) in serviceData"
          :key="index"
          class="service-item"
          @mouseenter="onItemEnter(index)"
          @mouseleave="onItemLeave"
        >
          <div v-show="currentServiceIndex === index" class="detail">
            <img :src="item.icon" alt="" class="icon" />
            <div class="title">{{ item.title }}</div>
            <div class="desc">{{ item.text }}</div>
          </div>
          <div
            v-show="currentServiceIndex !== index"
            class="cover"
            :style="{ backgroundImage: `url(${item.image})` }"
          >
            <h3 class="text">{{ item.title }}</h3>
          </div>
        </div>
      </div>
      <a href="#apply" class="btn">我要成为合作伙伴</a>
    </div>
    <div class="products-container">
      <h2 class="title">代理魔筷产品介绍</h2>
      <div class="products-box">
        <div
          v-for="(item, index) in productData"
          :key="index"
          class="product-item"
          :class="item.bgClass"
        >
          <h3 class="name">{{ item.name }}</h3>
          <div class="info">
            <div class="desc">{{ item.desc }}</div>
            <div class="points">
              <div v-for="(p, i) in item.points" :key="i" class="point">
                <img
                  src="https://mktv-in-cdn.mockuai.com/16270086421813948.png"
                  alt=""
                  class="icon"
                />
                <span class="text">{{ p }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="map-container">
      <h2 class="title">来自全国超百家合作伙伴的信任与选择</h2>
      <img
        src="https://mktv-in-cdn.mockuai.com/16270101557384023.png"
        alt=""
        class="map"
      />
    </div>
    <div class="apply-container">
      <h2 class="title">申请流程</h2>
      <div class="process-box">
        <div
          v-for="(item, index) in processData"
          :key="index"
          class="process-item"
        >
          <div class="icon-box">
            <img :src="item.icon" alt="" class="icon" />
          </div>
          <div class="text">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div id="apply" class="form-container">
      <h2 class="title">立即申请，成为魔筷代理商</h2>
      <div class="form-box">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          :inline="true"
          label-position="top"
        >
          <el-form-item prop="package_name" label="选择意向代理产品">
            <el-select
              v-model="form.package_name"
              multiple
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in options"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            prop="contact"
            label="联系人姓名"
            style="margin-right: 2.083333vw;"
          >
            <el-input v-model="form.contact" placeholder="请输入"></el-input>
          </el-form-item>

          <el-form-item prop="company" label="公司名称">
            <el-input v-model="form.company" placeholder="请输入"></el-input>
          </el-form-item>

          <el-form-item
            prop="mobile"
            label="联系人电话"
            style="margin-right: 2.083333vw;"
          >
            <el-input
              v-model="form.mobile"
              placeholder="请输入"
              :maxlength="11"
            ></el-input>
          </el-form-item>

          <el-form-item prop="company_size" label="公司规模人数">
            <el-input
              v-model="form.company_size"
              placeholder="请输入"
            ></el-input>
          </el-form-item>

          <el-form-item prop="city" label="所在城市">
            <el-cascader
              v-model="form.city"
              :options="regionList"
              :props="cascaderProps"
              placeholder="所在城市"
              @expand-change="handleItemChange"
            ></el-cascader>
          </el-form-item>

          <el-form-item
            label="您是从什么渠道了解魔筷的?"
            prop="source"
            class="learn_item"
          >
            <el-select
              v-model="form.source"
              placeholder="请选择"
              @change="where_learn"
            >
              <el-option
                v-for="(item, index) in optionData"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="learnInfo" v-if="visible">
            <el-input
              v-model="form.learnInfo"
              placeholder="请输入"
              class="learn"
            ></el-input>
          </el-form-item>

          <el-form-item prop="description" label="公司介绍">
            <el-input
              type="textarea"
              v-model="form.description"
              placeholder="请填写公司介绍（当前主营业务、过往业务、主营类型、数量）"
            ></el-input>
          </el-form-item>

          <div class="submit-btn" @click="submit">提交申请</div>
          <!-- <div class="tips">市场有风险 投资需谨慎</div> -->
        </el-form>
      </div>
    </div>

    <div v-show="searchResultModalVisible" class="result-modal search-result">
      <div class="modal-box">
        <img
          src="https://mktv-in-cdn.mockuai.com/16270256543822994.png"
          alt=""
          class="close-icon"
          @click="closeSearchModal"
        />
        <div class="title">查询结果</div>
        <div class="result">{{ searchResultText }}</div>
        <div class="btn" @click="closeSearchModal">知道了</div>
      </div>
    </div>
    <div v-show="successModalVisible" class="result-modal apply-success">
      <div class="modal-box">
        <img
          src="https://mktv-in-cdn.mockuai.com/16270256543822994.png"
          alt=""
          class="close-icon"
          @click="closeSuccessModal"
        />
        <div class="title">申请成功！我们将尽快与您联系！</div>
        <div class="text">以下为您提交的资料</div>
        <div class="content">
          <div class="item">
            <div class="label">姓名:</div>
            <div class="detail">{{ modalFields.contact }}</div>
          </div>
          <div class="item">
            <div class="label">电话:</div>
            <div class="detail">{{ modalFields.mobile }}</div>
          </div>
          <div class="item">
            <div class="label">公司名称:</div>
            <div class="detail">{{ modalFields.company }}</div>
          </div>
          <div class="item">
            <div class="label">公司规模人数:</div>
            <div class="detail">{{ modalFields.company_size }}</div>
          </div>
          <div class="item item-line">
            <div class="label">所在城市:</div>
            <div class="detail">{{ modalFields.city }}</div>
          </div>
          <div class="item item-line">
            <div class="label">公司介绍:</div>
            <div class="detail">{{ modalFields.description }}</div>
          </div>
        </div>
        <div class="btn" @click="closeSuccessModal">知道了</div>
      </div>
    </div>
  </div>
</template>

<script>
import util from '@/js/util'

const mobileCheck = (rule, value, callback) => {
  if (!util.isPhoneAvailable(value)) {
    return callback(new Error('请输入正确的手机号'))
  }
  callback()
}

export default {
  head: {
    title: '魔筷渠道合作-直播加盟-直播入驻-电商服务-魔筷星选',
    meta: [
      {
        name: 'Keywords',
        content:
          '魔筷代理商,服务商渠道加盟,服务商招商,渠道代理',
      },
      {
        name: 'Description',
        content:
          '【魔筷科技-直播电商服务平台】提供直播电商供应链服务,聚焦“网红+电商”两端核心资源,依托直播流量,服务网红商家直播运营,持续为客户创造更多价值。',
      },
    ],
  },
  data() {
    return {
      searchKey: '',
      searchResultText: '',
      searchBoxData: [
        {
          icon: 'https://mktv-in-cdn.mockuai.com/16269436079285748.png',
          title: '总部多重收益免费获取',
          desc: '产品销售返佣+各类扶持激励+交易佣金，收入不断',
        },
        {
          icon: 'https://mktv-in-cdn.mockuai.com/16269436079261774.png',
          title: '聚焦区域“网红+电商”两端核心资源',
          desc: '供应链产品核心资源+网红直播带货核心资源',
        },
        {
          icon: 'https://mktv-in-cdn.mockuai.com/16269436079091538.png',
          title: '抢占本地直播电商服务市场',
          desc: '依托庞大流量，服务本地市场，加快本地电商服务发展',
        },
      ],
      currentServiceIndex: -1,
      serviceData: [
        {
          icon: 'https://mktv-in-cdn.mockuai.com/16269450317863342.png',
          title: '技术保障',
          text: '技术团队六年开发沉淀，系统稳定',
          image: 'https://mktv-in-cdn.mockuai.com/16269453748825430.png',
        },
        {
          icon: 'https://mktv-in-cdn.mockuai.com/16269450317833414.png',
          title: '培训支持',
          text: '线上直播培训、线下集中培训相结合，快速熟悉业务',
          image: 'https://mktv-in-cdn.mockuai.com/16269453748857917.png',
        },
        {
          icon: 'https://mktv-in-cdn.mockuai.com/16269450317784319.png',
          title: '驻地支持',
          text: '渠道经理落地支持，帮助代理商业务组建',
          image: 'https://mktv-in-cdn.mockuai.com/16269453748873058.png',
        },
        {
          icon: 'https://mktv-in-cdn.mockuai.com/16269450317706937.png',
          title: '服务赋能',
          text: '帮助代理商组成服务能力，持续为客户创造更多价值',
          image: 'https://mktv-in-cdn.mockuai.com/16269453748903186.png',
        },
        {
          icon: 'https://mktv-in-cdn.mockuai.com/16269450317509582.png',
          title: '市场物料',
          text: '总部提供产品、活动等统一品牌物料',
          image: 'https://mktv-in-cdn.mockuai.com/16269453748936809.png',
        },
      ],
      productData: [
        {
          name: '魔筷星选网红版',
          bgClass: 'app-anchor',
          desc:
            '适用于处于成长阶段的主播或商家，通过魔筷星选的6大服务快速取得直播电商红利。',
          points: [
            '专业卖货工具',
            '直连快手小黄车',
            '海量货源智能推选',
            '直播电商服务',
            '助力网红高效成单',
          ],
        },
        {
          name: '魔筷星选旺铺版',
          bgClass: 'app-store',
          desc:
            '适用于规模化的品牌商家，魔筷将通过专项运营和深度服务，帮助商家顺利布局直播电商。',
          points: [
            '链接海量网红',
            '打造爆款商品',
            '直连快手小黄车',
            '系统安全稳定',
            '一件代发统仓统配 ',
          ],
        },
        {
          name: '酷管家',
          bgClass: 'scrm',
          desc:
            '适用于各类商家和主播，通过对粉丝的精细化私域运营，实现流量增长和快速变现。',
          points: [
            '挖掘粉丝最大价值',
            '精细化管理提高复购率',
            '运营工具化降低人力成本',
            '裂变工具扩大客户池',
          ],
        },
      ],
      processData: [
        {
          icon: 'https://mktv-in-cdn.mockuai.com/16270107918101736.png',
          title: '提交申请',
        },
        {
          icon: 'https://mktv-in-cdn.mockuai.com/16270107918074426.png',
          title: '线下审核',
        },
        {
          icon: 'https://mktv-in-cdn.mockuai.com/16270107918047528.png',
          title: '洽谈方案',
        },
        {
          icon: 'https://mktv-in-cdn.mockuai.com/16270107917914869.png',
          title: '签约合作',
        },
      ],
      options: ['网红版', '旺铺版', '酷管家'],

      optionData: [
        '朋友介绍',
        '百度搜索',
        '媒体报道',
        '大型活动(行业展会、会议等)',
        '其他',
      ],

      visible: false,
      cascaderProps: {
        value: 'label',
        children: 'list',
      },
      regionList: [],
      form: {
        package_name: [],
        contact: '',
        mobile: '',
        city: [],
        company: '',
        description: '',
        company_size: '',
        source: '',
        learnInfo: '',
      },
      modalFields: {
        package_name: '',
        contact: '',
        mobile: '',
        city: '',
        company: '',
        description: '',
        company_size: '',
      },
      rules: {
        package_name: [
          {
            required: true,
            message: '请选择产品',
            trigger: 'change',
          },
        ],
        contact: [
          { required: true, message: '请输入联系人姓名', trigger: 'change' },
        ],
        mobile: [
          {
            required: true,
            message: '请输入联系人手机号码',
            trigger: 'change',
          },
          { validator: mobileCheck, trigger: 'blur' },
        ],
        company: [
          { required: true, message: '请输入公司名称', trigger: 'change' },
        ],
        company_size: [
          { required: true, message: '请输入公司规模人数', trigger: 'change' },
        ],
        city: [
          { required: true, message: '请选择所在城市', trigger: 'change' },
        ],
        description: [
          { required: true, message: '请输入公司介绍', trigger: 'change' },
        ],
        source: [{ required: true, message: '请输入渠道', trigger: 'change' }],
        learnInfo: [
          { required: false, message: '请输入渠道', trigger: 'change' },
        ],
      },
      successModalVisible: false,
      searchResultModalVisible: false,
    }
  },
  mounted() {
    this.getRegionList().then((res) => {
      if (res.data) {
        this.regionList = res.data.map((item) => ({
          code: item.code,
          label: item.name,
          list: [],
        }))
      }
    })
  },

  methods: {
    onItemEnter(index) {
      this.currentServiceIndex = index
    },
    onItemLeave() {
      this.currentServiceIndex = -1
    },

    getRegionList(code) {
      return new Promise((resolve, reject) => {
        window.lib.api.get({
          api: 'ec/a/delivery/region/list',
          data: code ? { parent_code: code } : {},
          success: resolve,
          error: reject,
        })
      })
    },
    handleItemChange(data) {
      // console.log(data, this.regionList)
      const items = this.regionList.filter((item) => item.label === data[0])
      if (items && items.length) {
        this.getRegionList(items[0].code).then((res) => {
          if (res.data) {
            items[0].list = res.data.map((item) => ({
              code: item.code,
              label: item.name,
            }))
          }
        })
      }
    },
    submit() {
      this.$refs.form.validate((res) => {
        if (res) {
          if (this.visible) {
            this.form.source = this.form.learnInfo
          }
          delete this.form.learnInfo
          // console.log('提交form表单的数据', this.form)

          const params = {
            ...this.form,
            package_name: this.form.package_name.join(','),
          }
          this.modalFields = {
            ...params,
            city: this.form.city.join(''),
          }
          window.lib.api.post({
            api: 'ec/a/boss/agency/add',
            data: params,
            success: (res) => {
              console.log(res)
              this.$refs.form.resetFields()
              this.successModalVisible = true
              this.visible = false
            },
            error: (err) => {
              this.$message.error(err.msg)
            },
          })
        }
      })
    },
    closeSuccessModal() {
      this.successModalVisible = false
    },

    search() {
      if (!this.searchKey) {
        return this.$message.error('请输入搜索内容')
      }
      window.lib.api.get({
        api: 'ec/a/boss/toolbiz/agent/query/homepage',
        data: {
          company_name: this.searchKey,
        },
        success: (res) => {
          console.log(res)
          if (res.data && res.data.data && res.data.data.length) {
            console.log(222333, res.data.data[0].company_name)
            this.searchResultText = `${res.data.data[0].company_name}是魔筷官方授权代理商`
          } else {
            this.searchResultText =
              '对不起，暂未查到该代理商信息，请联系魔筷官网客服核实。'
          }
          this.searchResultModalVisible = true
        },
        error: (err) => {
          console.log(err.msg)
          this.searchResultText =
            '对不起，暂未查到该代理商信息，请联系魔筷官网客服核实。'
          this.searchResultModalVisible = true
        },
      })
    },
    closeSearchModal() {
      this.searchResultModalVisible = false
    },
    where_learn(value) {
      this.visible = value == '其他' ? true : false
      this.rules.learnInfo[0].required = this.visible ? true : false
    },
  },
}
</script>

<style lang="less" scoped>
@import '../style/common.less';

.channel-join-wrapper {
  padding-top: 90px;
  .banner-container {
    box-sizing: border-box;
    .pxToVw(height, 680);
    .pxToVw(padding-top, 211);
    background-color: #00092a;
    background-image: url(https://mktv-in-cdn.mockuai.com/16269412400782824.png);
    background-size: cover;
    background-repeat: no-repeat;
    .info {
      .pxToVw(margin-left, 240);
      h1.title {
        .pxToVw(margin-bottom, 22);
        line-height: 1.4;
        .pxToVw(font-size, 60);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      .desc {
        .pxToVw(margin-bottom, 60);
        .pxToVw(line-height, 33);
        .pxToVw(font-size, 24);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.8);
      }
      .btn {
        display: block;
        .pxToVw(width, 280);
        .pxToVw(height, 60);
        .pxToVw(line-height, 60);
        text-align: center;
        background-color: #fff;
        .pxToVw(border-radius, 30);
        .pxToVw(font-size, 24);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff6600;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          background-color: #ff6600;
          color: #fff;
        }
      }
    }
  }
  .search-container {
    box-sizing: border-box;
    .pxToVw(height, 986);
    .pxToVw(padding-top, 110);
    background-color: #fff;
    .search-box {
      .pxToVw(width, 930);
      margin: 0 auto 112 * @unitVw;
      display: flex;
      align-items: center;
      justify-content: center;
      .pxToVw(border-radius, 8);
      box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.1);
      .search-input {
        box-sizing: border-box;
        .pxToVw(width, 800);
        .pxToVw(height, 80);
        padding: 0 30 * @unitVw;
        border: none;
        outline: none;
        .pxToVw(border-top-left-radius, 8);
        .pxToVw(border-bottom-left-radius, 8);
        .pxToVw(font-size, 20);
        color: #333;
        &::placeholder {
          .pxToVw(font-size, 20);
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999;
        }
      }
      .btn {
        .pxToVw(width, 130);
        .pxToVw(height, 80);
        .pxToVw(line-height, 80);
        text-align: center;
        background-color: #ff6600;
        .pxToVw(border-top-right-radius, 8);
        .pxToVw(border-bottom-right-radius, 8);
        .pxToVw(font-size, 20);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
      }
    }
    > .title {
      .pxToVw(margin-bottom, 87);
      text-align: center;
      .pxToVw(line-height, 50);
      .pxToVw(font-size, 36);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .content-box {
      display: flex;
      align-items: center;
      .pxToVw(margin-left, 240);
      .info {
        .pxToVw(margin-right, 171);
        .item {
          display: flex;
          align-items: center;
          .pxToVw(margin-bottom, 52);
          .icon {
            .pxToVw(width, 58);
            .pxToVw(height, 58);
            .pxToVw(margin-right, 19);
          }
          .right {
            .title {
              .pxToVw(margin-bottom, 9);
              .pxToVw(line-height, 33);
              .pxToVw(font-size, 24);
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
            }
            .desc {
              .pxToVw(line-height, 25);
              .pxToVw(font-size, 18);
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
            }
          }
        }
        .btn {
          display: block;
          .pxToVw(width, 280);
          .pxToVw(height, 60);
          .pxToVw(line-height, 60);
          text-align: center;
          .pxToVw(border-radius, 30);
          background-color: #ff6600;
          .pxToVw(font-size, 24);
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          text-decoration: none;
          cursor: pointer;
          &:hover {
            font-weight: 600;
            text-shadow: 0px 7px 21px rgba(255, 102, 0, 0.4);
            box-shadow: 0px 7px 21px 0px rgba(255, 102, 0, 0.4);
          }
        }
      }
      .image {
        position: relative;
        .pxToVw(width, 680);
        .pxToVw(height, 446);
        .pxToVw(border-radius, 16);
        background-color: #00092a;
        background-image: url(https://mktv-in-cdn.mockuai.com/16269435920767640.png);
        background-size: cover;
        background-repeat: no-repeat;
        .left,
        .right {
          position: absolute;
          .pxToVw(width, 201);
          .pxToVw(height, 237);
        }
        .left {
          .pxToVw(left, -98);
          .pxToVw(bottom, 45);
        }
        .right {
          .pxToVw(right, -81);
          .pxToVw(top, 51);
        }
      }
    }
  }
  .service-container {
    box-sizing: border-box;
    .pxToVw(height, 860);
    .pxToVw(padding-top, 93);
    background: url(https://mktv-in-cdn.mockuai.com/16275719108767876.png) left
        top/cover no-repeat,
      linear-gradient(133deg, #00092a 0%, #001554 100%);
    > .title {
      .pxToVw(margin-bottom, 96);
      text-align: center;
      .pxToVw(line-height, 50);
      .pxToVw(font-size, 36);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
    .service-list {
      display: flex;
      align-items: center;
      margin: 0 240 * @unitVw;
      .service-item {
        position: relative;
        .pxToVw(width, 272);
        .pxToVw(height, 360);
        .pxToVw(margin-right, 20);
        background-color: #fff;
        .pxToVw(border-radius, 16);
        overflow: hidden;
        &:last-child {
          margin-right: 0;
        }
        .detail {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;
          .icon {
            .pxToVw(width, 72);
            .pxToVw(height, 72);
            .pxToVw(margin-bottom, 17);
          }
          .title {
            .pxToVw(margin-bottom, 10);
            .pxToVw(line-height, 33);
            .pxToVw(font-size, 24);
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
          }
          .desc {
            .pxToVw(width, 180);
            text-align: center;
            .pxToVw(line-height, 20);
            .pxToVw(font-size, 14);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
        }
        .cover {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #00092a;
          background-size: cover;
          background-repeat: no-repeat;
          display: flex;
          align-items: center;
          justify-content: center;
          .text {
            .pxToVw(font-size, 24);
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
          }
        }
      }
    }
    .btn {
      display: block;
      margin: 100 * @unitVw auto 0;
      .pxToVw(width, 280);
      .pxToVw(height, 60);
      .pxToVw(line-height, 60);
      text-align: center;
      .pxToVw(border-radius, 30);
      background-color: #fff;
      .pxToVw(font-size, 24);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        background-color: #ff6600;
        color: #fff;
      }
    }
  }
  .products-container {
    box-sizing: border-box;
    .pxToVw(height, 932);
    .pxToVw(padding-top, 92);
    background-color: #fff;
    > .title {
      .pxToVw(margin-bottom, 90);
      .pxToVw(line-height, 50);
      .pxToVw(font-size, 36);
      text-align: center;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
    .products-box {
      margin: 0 240 * @unitVw;
      display: flex;
      align-items: center;
      .product-item {
        position: relative;
        .pxToVw(width, 460);
        .pxToVw(height, 600);
        .pxToVw(margin-right, 30);
        .pxToVw(border-radius, 16);
        overflow: hidden;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #001554;
        &:last-child {
          margin-right: 0;
        }
        &.app-anchor {
          background-image: url(https://mktv-in-cdn.mockuai.com/16270073947208399.png);
        }
        &.app-store {
          background-image: url(https://mktv-in-cdn.mockuai.com/16270073947997630.png);
        }
        &.scrm {
          background-image: url(https://mktv-in-cdn.mockuai.com/16270073948081185.png);
        }
        .info {
          visibility: hidden;
          position: absolute;
          left: 0;
          // bottom: 0;
          .pxToVw(top, 398);
          padding: 0 40 * @unitVw 30 * @unitVw;
          .desc {
            .pxToVw(line-height, 25);
            .pxToVw(font-size, 18);
            .pxToVw(margin-bottom, 16);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
          .points {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .point {
              display: flex;
              align-items: center;
              flex: 0 0 50%;
              .pxToVw(margin-bottom, 15);
              .icon {
                .pxToVw(width, 20);
                .pxToVw(height, 15);
                .pxToVw(margin-right, 8);
              }
              .text {
                .pxToVw(font-size, 14);
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
              }
            }
          }
        }
        .name {
          visibility: visible;
          position: absolute;
          .pxToVw(left, 40);
          .pxToVw(bottom, 34);
          .pxToVw(font-size, 32);
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }
        &:hover {
          background-color: transparent;
          &.app-anchor {
            background-image: url(https://mktv-in-cdn.mockuai.com/16270097428527591.png);
          }
          &.app-store {
            background-image: url(https://mktv-in-cdn.mockuai.com/16270097429057388.png);
          }
          &.scrm {
            background-image: url(https://mktv-in-cdn.mockuai.com/16270097429117888.png);
          }
          .name {
            visibility: hidden;
          }
          .info {
            visibility: visible;
          }
        }
      }
    }
  }
  .map-container {
    box-sizing: border-box;
    .pxToVw(height, 1298);
    .pxToVw(padding-top, 92);
    background: url(https://mktv-in-cdn.mockuai.com/16270101557082016.png) top
        left/ 100% 1298 * 100/1920vw no-repeat,
      linear-gradient(133deg, #00092a 0%, #001554 100%);
    > .title {
      .pxToVw(margin-bottom, 33);
      text-align: center;
      .pxToVw(line-height, 50);
      .pxToVw(font-size, 36);
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
    .map {
      display: block;
      margin: 0 auto;
      .pxToVw(width, 1338);
    }
  }
  .apply-container {
    box-sizing: border-box;
    .pxToVw(height, 534);
    .pxToVw(padding-top, 92);
    > .title {
      .pxToVw(margin-bottom, 119);
      text-align: center;
      .pxToVw(line-height, 50);
      .pxToVw(font-size, 36);
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
    .process-box {
      display: flex;
      align-items: center;
      margin: 0 360 * @unitVw;
      .process-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        .pxToVw(margin-right, 240);
        &::after {
          content: '';
          position: absolute;
          .pxToVw(right, -216);
          .pxToVw(top, 50);
          .pxToVw(width, 190);
          .pxToVw(height, 20);
          background-image: url(https://mktv-in-cdn.mockuai.com/16270107918162801.png);
          background-size: cover;
          background-repeat: no-repeat;
        }
        &:last-child {
          margin-right: 0;
          &::after {
            content: '';
            width: 0;
            height: 0;
          }
        }
        .icon-box {
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          .pxToVw(width, 120);
          .pxToVw(height, 120);
          .pxToVw(margin-bottom, 20);
          border: 1px solid #fff;
          border-radius: 50%;
          background: rgba(255, 255, 255, 0.8);
          box-shadow: 0px 6px 19px 0px rgba(0, 0, 0, 0.06);
          .icon {
            .pxToVw(width, 72);
            .pxToVw(height, 72);
          }
        }
        .text {
          .pxToVw(font-size, 24);
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
        }
      }
    }
  }
  .form-container {
    box-sizing: border-box;
    .pxToVw(min-height, 1100);
    .pxToVw(padding-top, 92);
    .pxToVw(padding-bottom, 98);
    background-image: url(https://mktv-in-cdn.mockuai.com/16270126763335253.png);
    background-position: bottom right;
    background-size: 583 * @unitVw 655 * @unitVw;
    background-repeat: no-repeat;
    > .title {
      .pxToVw(margin-bottom, 69);
      text-align: center;
      .pxToVw(line-height, 50);
      .pxToVw(font-size, 36);
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
    .form-box {
      margin: 0 360 * @unitVw;
      .el-form-item {
        .pxToVw(margin-right, 0);
        .pxToVw(margin-bottom, 38);
        /deep/ .el-form-item__label {
          // .pxToVw(padding-left, 20);
          .pxToVw(padding-bottom, 10);
          .pxToVw(line-height, 25);
          .pxToVw(font-size, 18);
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #333333;
          &::before {
            content: '';
            margin-right: 0;
          }
        }
      }
      .el-select,
      .el-cascader,
      .el-input {
        /deep/ input {
          box-sizing: border-box;
          .pxToVw(height, 52);
          .pxToVw(line-height, 52);
          &::placeholder {
            .pxToVw(font-size, 18);
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
            color: #999999;
          }
          &:focus {
            outline: none;
            border: 1px solid #ff6600;
          }
        }
      }
      .el-select {
        .pxToVw(width, 1200);
      }
      .el-input {
        .pxToVw(width, 580);
      }
      .learn {
        .pxToVw(width, 1200);
      }
      .learn_item {
        .pxToVw(margin-bottom, 20);
      }
      .el-cascader {
        .pxToVw(width, 1200);
      }
      .el-textarea {
        .pxToVw(width, 1200);
        /deep/ textarea {
          .pxToVw(height, 120);
          &::placeholder {
            .pxToVw(font-size, 18);
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
            color: #999999;
          }
          &:focus {
            outline: none;
            border: 1px solid #ff6600;
          }
        }
      }
      .submit-btn {
        margin: 60 * @unitVw auto 0;
        .pxToVw(width, 232);
        .pxToVw(height, 60);
        .pxToVw(line-height, 60);
        text-align: center;
        .pxToVw(border-radius, 30);
        background-color: #ff6600;
        .pxToVw(font-size, 24);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
        &:hover {
          font-weight: 600;
          box-shadow: 0px 7px 21px 0px rgba(255, 102, 0, 0.4);
        }
      }
      .tips {
        .pxToVw(margin-top, 36);
        text-align: center;
        .pxToVw(line-height, 25);
        .pxToVw(font-size, 18);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
    }
  }
  .result-modal {
    position: fixed;
    z-index: 20000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    .modal-box {
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .pxToVw(border-radius, 4);
      background-color: #fff;
    }
    .close-icon {
      position: absolute;
      .pxToVw(right, 20);
      .pxToVw(top, 20);
      .pxToVw(width, 16);
      .pxToVw(height, 16);
      cursor: pointer;
    }
    .btn {
      margin: 50 * @unitVw auto 0;
      .pxToVw(width, 160);
      .pxToVw(height, 44);
      .pxToVw(line-height, 44);
      background-color: #ff6600;
      .pxToVw(border-radius, 30);
      text-align: center;
      .pxToVw(font-size, 18);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
    &.apply-success {
      .modal-box {
        .pxToVw(width, 720);
        .pxToVw(height, 447);
        padding: 55 * @unitVw 40 * @unitVw;
        .title {
          .pxToVw(margin-bottom, 30);
          text-align: center;
          .pxToVw(line-height, 33);
          .pxToVw(font-size, 24);
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .text {
          .pxToVw(margin-bottom, 16);
          .pxToVw(line-height, 25);
          .pxToVw(font-size, 18);
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .content {
          box-sizing: border-box;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          .pxToVw(width, 640);
          .pxToVw(padding, 20);
          .pxToVw(border-radius, 4);
          background-color: #f8f8f8;
          .item {
            flex: 0 0 50%;
            display: flex;
            align-items: center;
            .pxToVw(margin-bottom, 14);
            &.item-line {
              flex: 0 0 100%;
            }
            .label,
            .detail {
              .pxToVw(margin-right, 8);
              .pxToVw(font-size, 14);
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
            }
          }
        }
      }
    }
    &.search-result {
      .modal-box {
        .pxToVw(width, 620);
        .pxToVw(height, 305);
        .pxToVw(padding-top, 55);
        .title {
          .pxToVw(margin-bottom, 30);
          text-align: center;
          .pxToVw(line-height, 33);
          .pxToVw(font-size, 24);
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .result {
          text-align: center;
          .pxToVw(line-height, 25);
          .pxToVw(font-size, 18);
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
}
</style>
